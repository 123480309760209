/* layout */
html,.container {
  position:relative;
  min-height:100%;
}

body {
  min-height:100%;
  height:auto;
}

.widget {
  margin-top: 5px;
}

.page-content {
    min-height:100%;
    box-sizing: border-box;
    height:auto;
    padding-bottom: 140px;/*等于或略大于footer的高度*/
}

.site-footer {
  position: absolute;
  bottom: 0;
  padding-bottom: 0rem;
  width:100%;

  /* footer style */
  color: #99999a;
  background-color: #FAFAFB;

  .left {
    a {
      color: #666;
      border-bottom:1px dotted;
      text-decoration:none;
    }
    a:hover {
      color:red;
      border-bottom:1px dotted;
    }
  }
}


/* Effects
—————————————————————————————————————————————————— */

.blinking_underscore {
  -webkit-animation: blinking_underscore_frame 1.5s infinite ease;
  -moz-animation: blinking_underscore_frame 1.5s infinite ease;
  -ms-animation: blinking_underscore_frame 1.5s infinite ease;
  -o-animation: blinking_underscore_frame 1.5s infinite ease;
  animation: blinking_underscore_frame 1.5s infinite ease;
}

.logo {
  margin-bottom:12.5px;
  font-weight:$font-weight-bold;
}

.nav {
  line-height: $base-line-height;

  .page-link {
    color: $text-color;
    line-height: 1.0;
    display: block;
    padding: 5px 0px 0px 0px;


    li {
      font-size:1.5rem;
      list-style:none;
      font-weight: 100;
      margin-bottom:1rem;
    }

    a:active {
      font-weight: 500;
    }
  }
}

/* site-nav */

@include media-query($on-laptop) {
  .site-nav {

    /* flex box */
    display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
    display: -moz-box; /* Firefox 17- */
    display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
    display: -moz-flex; /* Firefox 18+ */
    display: -ms-flexbox; /* IE 10 */
    display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */// Import partials from `sass_dir` (defaults to `_sass`)

    opacity: 0;
    height: 0;
    -webkit-transition: all .4s;
    transition: all .4s;

    -webkit-justify-content: center;
    justify-content:center;

    @include media-query($on-laptop) {
      border-top:1px solid #ddd;
      border-bottom:1px solid #ddd;
    }
  }

  #menu-toggle:checked+.site-nav {
    opacity: 1;
    height: auto;
    margin-bottom:-25px;
  }
}

input#menu-toggle {
  display:none;
}

.nav .menu-icon {
  display:none;
  position:absolute;
  right:5%;

  @include media-query($on-laptop) {
    top: 67px;

    @include media-query(400px) {
      top: 13px;
    }

    display: block;
    text-align: center;

    &:hover {
      >svg {
        path {
          fill: $grey-color;
        }
      }
    }

    > svg {
      width: 18px;
      height: 15px;


      path {
        fill: $grey-color-dark;
  } } }
}


.site-header {
  text-align:right;
  @include media-query($on-laptop){
    text-align: center;
    margin-bottom: 40px;
  }
  nav a {
    font-weight:400;
  }
}

.post {
  @extend .dont-break-out;
}

.post-list {

}

.post_header {
  margin-bottom:1rem;
}

.post_title {
  margin-bottom:0.5rem;
}

.post_date {
  color:#888;
  font-size:1.4rem;
}

p.readmore {
  text-align:right;
}

.post_meta {
  .author_meta {
    img {
      height:24px;
      width:24px;
    }
  }
}

.post_archive {

}

.article_meta {

}

.article-content a {
  border-bottom:1px dotted red;
  text-decoration:none;
}
.article-content a:hover {
  color:red;
  border-bottom:1px dotted red;
}

.article-footer {

  padding:2rem 0;
  border-top:1px solid #ddd;
  border-bottom:1px solid #ddd;

  .share {
    text-align:center;
  }

  .share-link {
    text-decoration:underline;
    margin-left:8px;
  }

}

.article_share {

}

.article_share .location {

}

.artilce-prev {

}

.article-next {

}

/* author info */


.author {
  @media (max-width: $on-laptop) {
    display:inline-block;
    text-align:center;
  }
}

.authorimage {
  @media (min-width: $on-laptop+1) {
    float:left;
  }
  display:inline-block;
  width: 90px;
  height: 90px;
  border-radius: 5%;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  background-position: center center !important;
  margin-right: 10px;
}

.authorinfo {
  .bio {
    color:#999;
  }
}



/* footer */

.footer {
  float:left;
}

.left {
  text-align:center;

  @media (min-width: $on-laptop) {
    text-align:left;
  }
}

.right {
  text-align:center;

  @media (min-width: $on-laptop) {
    text-align:right;
  }

}

/* icon */

.icon {
  vertical-align: bottom;
}

body.custom-background {
  background-image: url('/images/background.png');
  background-repeat: repeat;
  background-position: top left;
  background-attachment: scroll; }

/* Archive page */

.archive_list {
  margin-left:5%;
  width:90%;
  margin-bottom:3rem;
}

.category-name {
  padding-top:20px;
  text-transform:lowercase;
  font-variant:small-caps;
  h2 {
   letter-spacing:0;
   border-bottom: 1px solid #ddd;
  }
}

div.post_title span.date {
  font-size: 80%;
  color: $grey-color;
}

/* Pagination */


.pagination {
  width:50%;
}

.pagination.older-post{
  float: right;
  text-align: right;
}

/* others */

.small-font{
  font-size:$small-font-size;
}

/* Media Queries
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*
Note: The best way to structure the use of media queries is to create the queries
near the relevant code. For example, if you wanted to change the styles for buttons
on small devices, paste the mobile query code up in the buttons section and style it
there.
*/

/* Larger than mobile */
@media (min-width: 400px) {}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {}

/* Larger than desktop */
@media (min-width: 1000px) {}

/* Larger than Desktop HD */
@media (min-width: 1200px) {}

/* Smaller than tablet */
@media (max-width: 750px) {}

/* Smaller than tablet */
@media (max-width: 550px) {}


/* ------------------------------------
 * frames
 * ------------------------------------ */

@keyframes "blinking_underscore_frame" {
 0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   	filter: alpha(opacity=0);
   	opacity: 0;
 }
 50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
   	filter: alpha(opacity=100);
   	opacity: 1;
 }
 100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   	filter: alpha(opacity=0);
   	opacity: 0;
 }

}

@-moz-keyframes blinking_underscore_frame {
 0% {
   filter: alpha(opacity=0);
   opacity: 0;
 }
 50% {
   filter: alpha(opacity=100);
   opacity: 1;
 }
 100% {
   filter: alpha(opacity=0);
   opacity: 0;
 }

}

@-webkit-keyframes "blinking_underscore_frame" {
 0% {
   filter: alpha(opacity=0);
   opacity: 0;
 }
 50% {
   filter: alpha(opacity=100);
   opacity: 1;
 }
 100% {
   filter: alpha(opacity=0);
   opacity: 0;
 }

}

@-ms-keyframes "blinking_underscore_frame" {
 0% {
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   filter: alpha(opacity=0);
   opacity: 0;
 }
 50% {
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
   filter: alpha(opacity=100);
   opacity: 1;
 }
 100% {
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   filter: alpha(opacity=0);
   opacity: 0;
 }
}

@-o-keyframes "blinking_underscore_frame" {
 0% {
   filter: alpha(opacity=0);
   opacity: 0;
 }
 50% {
   filter: alpha(opacity=100);
   opacity: 1;
 }
 100% {
   filter: alpha(opacity=0);
   opacity: 0;
 }
}
